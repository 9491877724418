/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 :root {
    --react-pdf-text-layer: 1;
    --highlight-bg-color: rgba(180, 0, 170, 1);
    --highlight-selected-bg-color: rgba(0, 100, 0, 1);
  }
  
  @media screen and (forced-colors: active) {
    :root {
      --highlight-bg-color: Highlight;
      --highlight-selected-bg-color: ButtonText;
    }
  }
  
  [data-main-rotation='90'] {
    transform: rotate(90deg) translateY(-100%);
  }
  [data-main-rotation='180'] {
    transform: rotate(180deg) translate(-100%, -100%);
  }
  [data-main-rotation='270'] {
    transform: rotate(270deg) translateX(-100%);
  }
  
  .textLayer {
    position: absolute;
    text-align: initial;
    inset: 0;
    overflow: hidden;
    line-height: 1;
    text-size-adjust: none;
    forced-color-adjust: none;
    transform-origin: 0 0;
    z-index: 2;
  }
  
  .textLayer :is(span, br) {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    margin: 0;
    transform-origin: 0 0;
  }
  
  /* Only necessary in Google Chrome, see issue 14205, and most unfortunately
   * the problem doesn't show up in "text" reference tests. */
  .textLayer span.markedContent {
    top: 0;
    height: 0;
  }
  
  .textLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: var(--highlight-bg-color);
    border-radius: 4px;
  }
  
  .textLayer .highlight.appended {
    position: initial;
  }
  
  .textLayer .highlight.begin {
    border-radius: 4px 0 0 4px;
  }
  
  .textLayer .highlight.end {
    border-radius: 0 4px 4px 0;
  }
  
  .textLayer .highlight.middle {
    border-radius: 0;
  }
  
  .textLayer .highlight.selected {
    background-color: var(--highlight-selected-bg-color);
  }
  
  /* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
  .textLayer br::selection {
    background: transparent;
  }
  
  .textLayer .endOfContent {
    display: block;
    position: absolute;
    inset: 100% 0 0;
    z-index: -1;
    cursor: default;
    user-select: none;
  }
  
  .textLayer .endOfContent.active {
    top: 0;
  }
  
  .hiddenCanvasElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    display: none;
  }
  