.forgot-body {
    background: #F0F2FF;
    padding: 140px 20px;
}

.forgot-body h4 {
    color: #07142D;
    font-family: "Sora", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    margin-bottom: 40px;
}

.forgot-body p {
    font-size: 18px;
    color: #8A8B98;
    margin-bottom: 20px;
}

.forgot-box {
    width: 444px;
    margin: 0 auto 0;
    background-color: #FFFFFF;
    padding: 40px 48px;
    box-shadow: 0px 16px 32px 0px #17226A14;
}

.forgot-box .form-group {
    margin-bottom: 30px;
}

.btn-forgot {
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    background-color: #4057E3;
    border-radius: 4px;
}

.btn-forgot:hover {
    background: #586EF2;
    color: #fff;
}

.verification-box h4 {
    margin-bottom: 05px;
}

.input-password {
    position: relative;
}

.website-wrap .input-password .pass_eye {
    position: absolute;
    right: 0;
    top: 45px;
    cursor: pointer;
}

.active_pass::after {
    content: ' ';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #586EF2;
}

@media (max-width:575px) {
    .forgot-body {
        padding: 45px 10px;
    }

    .forgot-box {
        width: 100%;
        padding: 40px 20px;
    }
}