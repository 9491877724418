.success-page-body {
    padding: 100px 20px;
    background-image: url('../../assets/img/bg_union.svg');
    background-repeat: no-repeat;
    background-position: center right;
}

.success-page-box {
    text-align: center;
}

.success-page-box h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 20px 0;
}

.success-page-box p {
    margin-bottom: 60px;
}

.success-page-body .back-btn {
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #07142D;
    padding: 16px 30px;
    border-radius: 4px;
    transition: 0.3s;
}

.success-page-body .back-btn:hover {
    background-color: #07142D;
    color: #FFFFFF !important;
    transition: 0.3s;
}

.msg-box {
    width: 730px;
    margin: 120px auto 80px;
    display: flex;
    padding: 35px 45px;
    border: 12px solid #F0F2FF;
    background-color: #FFFFFF;
}

.msg-box img {
    margin-right: 15px;
}

.msg-box p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #22315D;
}

.msg-box a {
    color: #4057E3;
}

@media (max-width:575px) {
    .success-page-body {
        padding: 70px 10px;
    }

    .msg-box {
        margin: 120px auto 40px;
        width: 100%;
        display: block;
        padding: 20px;
    }

    .msg-box img {
        margin-bottom: 15px;
    }
}