@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

body {
    font-family: "Manrope", sans-serif;
}

.dashboard-wrap {
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-feature-settings: 'clig' off, 'liga' off;
    margin: 0;
    background-color: #F5F4F8;
    color: #07142D;
    padding-top: 0;
}

.dashboard-wrap {
    margin-left: 265px;
    margin-top: 68px;
    min-height: calc(100vh - 68px);
}

.users-screen {
    background-color: #FFFFFF;
    padding: 20px;
}

@media (max-width:1024px) {
    .dashboard-wrap {
        margin-left: 0;
    }

    .users-screen {
        padding: 16px;
    }
}

p:last-child {
    margin-bottom: 0;
}

select,
input {
    font-family: "Manrope", sans-serif;
}

::placeholder {
    color: #99A4B1;
}

.form-control::placeholder {
    color: #99A4B1;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

ul,
ul li {
    list-style: none;
}

a,
a:hover {
    text-decoration: none;
}

a {
    color: #4057E3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Manrope", sans-serif;
    color: #07142D;
    margin-bottom: 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 12px;
    font-weight: 500;
}

.text-12 {
    font-size: 12px;
    font-weight: 500;
}

.text-14 {
    font-size: 14px;
    font-weight: 500;
}

.f-100 {
    font-weight: 100 !important;
}

.f-200 {
    font-weight: 200 !important;
}

.f-300 {
    font-weight: 300 !important;
}

.f-400 {
    font-weight: 400 !important;
}

.f-500 {
    font-weight: 500 !important;
}

.f-600 {
    font-weight: 600 !important;
}

.f-700 {
    font-weight: 700 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.padding-reg {
    padding: 24px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}

.place-items-center {
    place-items: center;
}

.place-content-center {
    place-content: center;
}

.place-content-end {
    place-content: end;
}

@media (max-width:575px) {
    .padding-reg {
        padding: 12px;
    }
}

.text-dark {
    color: #07142D !important;
}

.text-light {
    color: #99A4B1 !important;
}

.text-red {
    color: #FD151B !important;
}

.text-green {
    color: #12B76A !important;
}

.bg-white {
    background-color: #FFFFFF;
}

a.text-light:focus,
a.text-light:hover {
    color: #99A4B1 !important;
}

/* popup/ modal csss */

.modal-backdrop {
    background-color: #173361;
}

.modal-header {
    border-bottom: unset;
    padding: 24px 24px 12px;
}

.modal-footer {
    border-top: unset;
    padding: 24px;
}

.modal-body {
    padding: 0 24px;
}

.modal-title {
    font-size: 16px;
    color: #07142D;
    font-weight: 700;
}

.modal-header .close {
    margin: -24px -24px 0px auto;
}

.modal-content {
    border: unset;
}

.modal-content .modal-dialog {
    margin: 0;
}

.modal-box-millitary {
    width: 590px;
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 844px;
    }

    .modal-lg-work-exp {
        width: 590px;
    }

    .application-detail .modal-md-education .modal-box {
        width: 590px;
    }
}

/* custom modal css */
.cust-modal-show {
    opacity: 1;
    visibility: visible;
}

.cust-modal-hide {
    opacity: 0;
    visibility: hidden;
}

.cust-modal-hide .form-group {
    opacity: 0;
}

.cust-modal-hide button {
    opacity: 0;
}

.cust-modal-hide .form-group {
    display: none;
}

.cust-modal-show .modal-box {
    animation: fadeInDown 0.4s ease;
}

.cust-modal-hide .modal-box {
    animation: fadeOutUp 0.5s ease;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #1733618a;
    opacity: 1;
    overflow: auto;
}

.custom-modal .modal-backdrop {
    z-index: 1;
}

.cust-modal-lg .modal-box {
    width: 740px;
}

.cust-modal-sm .modal-box {
    width: 420px;
}

.cust-modal-md .modal-box {
    width: 500px;
}

.custom-modal .modal-box {
    z-index: 2;
    margin: 6% auto;
    background-color: #FFFFFF;
    border-radius: 6px;
}

@media (max-width:991px) {

    .cust-modal-lg .modal-box,
    .cust-modal-sm .modal-box,
    .cust-modal-md .modal-box {
        width: 95%;
    }

    .custom-modal .modal-box {
        margin: 1.75rem auto;
    }
}



/* form css */

.asterisk {
    color: #FD151B;
}

.input-helper {
    font-size: 11px;
    margin-top: 6px;
    line-height: 15px;
    font-weight: 500;
    color: #344152;
}

/* input text */
input {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
    position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input,
.form-control {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
    position: relative;
    width: 100%;
    transition: 0.3s;
    height: unset;
}

textarea {
    resize: none;
    height: 160px !important;
}

/* Style all options */
select option {
    padding: 20px;
}

/* Style the selected option */
select option:checked {
    background-color: #3498db;
}

select:hover,
input:hover {
    outline: none;
    box-shadow: 4px 4px 16px 0px #4057E31A;
    border: 1px solid #4057E3;
    transition: 0.5s;
}

input:focus-visible,
.form-control:focus-visible,
.form-control:focus {
    outline: none;
    border: 1px solid #4057E3;
    box-shadow: unset;
    transition: 1s;
}

button:focus {
    outline: none;
}

select.form-control:not([size]):not([multiple]) {
    height: unset;
}

/* Select Dropndown */
.lm-select {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
}

.lm-select:focus-visible,
.lm-select:focus {
    border: 1px solid #4057E3;
    outline: none;
    box-shadow: unset;
}

.lm-select:hover {
    border: 1px solid #4057E3;
    outline: none;
    box-shadow: 4px 4px 16px 0px #4057E31A;
}

textarea:hover {
    border: 1px solid #4057E3;
    outline: none;
    box-shadow: 4px 4px 16px 0px #4057E31A;
}

/* custom select dropdown */

.dashboard-wrap .basic-single .select__menu,
.modal-open .basic-single .select__menu,
.select__menu {
    z-index: 9999 !important;
    border: 1px solid #EAEDF0;
    border-radius: 4px !important;
    box-shadow: 0px 8px 16px -4px #1018280A !important;
    min-width: 220px;
    padding: 4px 5px;
}

.dashboard-wrap .basic-single .select__control,
.modal-open .basic-single .select__control {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    padding: 0 0 0 12px;
    min-height: 37px;
}

.dashboard-wrap .basic-single .select__value-container,
.modal-open .basic-single .select__value-container {
    padding: 0;
}

.dashboard-wrap .basic-single .select__single-value,
.modal-open .basic-single .select__single-value {
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
}

.dashboard-wrap .select__option,
.modal-open .select__option,
.select__option {
    color: #07142D !important;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 6px;
    margin-bottom: 4px;
}

.dashboard-wrap .select__option:hover,
.modal-open .select__option:hover,
.select__option:hover {
    background-color: #F3F4F6;
}

.dashboard-wrap .select__option--is-selected,
.modal-open .select__option--is-selected,
.select__option--is-selected {
    background-color: #F3F4F6 !important;
}

.dashboard-wrap .select__option--is-focused,
.modal-open .select__option--is-focused,
.select__option--is-focused {
    background-color: #F3F4F6 !important;
}

.dashboard-wrap .select__placeholder,
.modal-open .select__placeholder {
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
}

.dashboard-wrap .basic-single .select__control:hover,
.modal-open .basic-single .select__control:hover {
    border: 1px solid #4057E3;
    box-shadow: none;
}

.dashboard-wrap .basic-single .select__control--is-focused,
.modal-open .basic-single .select__control--is-focused {
    border: 1px solid #4057E3;
    box-shadow: none;
}

.select__indicator svg {
    transition: 0.3s;
}

.basic-single .select__control--menu-is-open .select__indicator svg,
.modal-open .basic-single .select__control--menu-is-open .select__indicator svg {
    transform: rotate(180deg);
    transition: 0.3s;
}

.dashboard-wrap .select__indicator-separator,
.modal-open .select__indicator-separator {
    display: none;
}

.select__menu ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 8px;
}

.select__menu ::-webkit-scrollbar-track {
    background: #ffffff00;
}

.select__menu ::-webkit-scrollbar-thumb {
    background: #D1D5DC;
}

.select__menu ::-webkit-scrollbar-thumb:hover {
    background: #D1D5DC;
}

@media (max-width:575px) {

    .dashboard-wrap .basic-single .select__menu,
    .modal-open .basic-single .select__menu {
        margin-top: 0;
    }
}

/* label css */
label,
.label {
    color: #99A4B1;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 4px;
}

.input-dollar {
    position: relative;
}

.input-dollar::after {
    content: '$';
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    right: 10px;
    top: 35px;
    color: #07142D;
    /* background: #fff; */
    padding: 0 0 0 5px;
}

.dynamic-form-group::after{
    top: unset;
    bottom: 10px;
}

.input-percentage {
    position: relative;
}

.input-percentage::after {
    content: '%';
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    right: 10px;
    top: 35px;
    color: #07142D;
    background: #fff;
    padding: 0 0 0 5px;
}

.input-month-text {
    position: relative;
}

.input-month-text::after {
    content: 'months';
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    right: 10px;
    top: 35px;
    color: #97A3B2;
    background: #fff;
    padding: 0 0 0 5px;
}

.input-date {
    position: relative;
}

.input-date input {
    padding: 8px 12px 9px;
    background-image: url('assets/icons/date-icon.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 18px;
}

/* .input-date::after {
    content: ' ';
    position: absolute;
    height: 18px;
    width: 18px;
    background-image: url('assets/icons/date-icon.svg');
    right: 10px;
    top: 35px;
} */

.input-OTP {
    display: flex;
    gap: 10px;
}

.input-OTP input {
    text-align: center;
    height: 48px;
    width: 48px;
    font-size: 18px;
    font-weight: 700;
    color: #4057E3 !important;
}

.if_invalid input {
    color: #FD151B !important;
    border: 1px solid #FD151B;
}

.invalid-feedback svg{
    margin-right: 4px;
}

.invalid-feedback {
    color: #FD151B !important;
}

.input-OTP input::placeholder {
    color: #97A3B2;
}

/* custom date picker css */
.react-datepicker {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    box-shadow: 0px 8px 16px -4px #1018280A;
    padding: 05px 05px 05px;
    font-family: "Manrope", sans-serif;
    display: flex;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 10;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__close-icon {
    right: 30px;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #FFFFFF;
    color: #99A4B1;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 0px;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-datepicker__header {
    border-bottom: unset;
    background-color: #FFFFFF;
    padding: 0;
}

.react-datepicker__navigation--previous {
    left: 7px;
    top: 10px;
}

.react-datepicker__navigation--next {
    right: 7px;
    top: 10px;
}

.react-datepicker__current-month {
    font-size: 14px;
    font-weight: 700;
    color: #07142D;
    margin-top: 8px;
    margin-bottom: 10px;
}

.react-datepicker__day-name {
    visibility: hidden;
    font-size: 14px;
    font-weight: 500;
    color: #99A4B1;
    width: 36px;
    margin: 0 0 5px;
}

.react-datepicker__day-name::first-letter {
    visibility: visible;
    margin-left: 6px;
}

.react-datepicker__day {
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
    border-radius: 0;
    margin: 0rem;
    line-height: 32px;
    width: 36px;
    height: 32px;
    border-right: 1px solid #F0F2FF;
    border-bottom: 1px solid #F0F2FF;
}

.react-datepicker__month .react-datepicker__week:first-child .react-datepicker__day {
    border-top: 3px solid #F0F2FF;
}

.react-datepicker__month .react-datepicker__week .react-datepicker__day:first-child {
    border-left: 1px solid #F0F2FF;
}

.react-datepicker__day--outside-month {
    color: #99A4B1;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0;
    background-color: #F0F2FF;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    color: #FFFFFF;
    border-radius: 0;
    background-color: #4057E3 !important;
}

.react-datepicker__day--range-end {
    color: #FFFFFF;
    border-radius: 0;
    background-color: #4057E3 !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 4px;
    z-index: 1;
    opacity: 0;
}

.two-month-datepicker .react-datepicker {
    display: flex;
}

.two-month-datepicker input {
    padding: 8px 55px 8px 12px;
}

/* .two-month-datepicker .react-datepicker__day-name {
    visibility: hidden;
    font-size: 14px;
    font-weight: 500;
    color: #99A4B1;
    width: 1.7rem;
    margin: 0.166rem;
} */

.two-month-datepicker .react-datepicker__week {
    display: grid;
    grid-template-columns: 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857%;
}

.react-datepicker__month-container .react-datepicker-year-header {
    margin-top: 7px;
}

.react-datepicker__month-text {
    padding: 4px;
    border-radius: 3px;
}

.react-datepicker__month-text--selected {
    background-color: #4057e3;
    color: #FFF;
}

@media (max-width:575px) {
    .two-month-datepicker input {
        width: 175px;
    }
}

@media (max-width:575px) {
    .two-month-datepicker .react-datepicker {
        display: grid;
    }
}

/* disable input  */
.form-control:disabled,
.form-control[readonly],
input:disabled {
    background-color: #F7F7F7;
    color: #99A4B1;
}

/* checkbox css */
input[type=checkbox] {
    position: relative;
    border: 2px solid #D1D5DC;
    border-radius: 4px;
    background: none;
    cursor: pointer;
    line-height: 0;
    outline: 0;
    padding: 0 !important;
    margin-top: 3px;
    margin-right: 5px;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
}

input[type=checkbox]:hover {
    border: 2px solid #4056e373;
}

input[type=checkbox]:checked {
    background-color: #4057E3;
    border: 2px solid #4057E3;
    opacity: 1;
}

input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 54%;
    width: 5px;
    height: 9px;
    border: solid #FFFFFF00;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
}

input[type=checkbox]:checked:before {
    border: solid #FFF;
    border-width: 0 2px 2px 0;
}

input[type=checkbox]~label,
.form-check label {
    color: #07142D;
    font-weight: 600;
    cursor: pointer;
}

input[type="checkbox"]:focus {
    border: 2px solid #4057E3;
}

/* Style the custom radio button */
input[type="radio"].form-check-input {
    display: block;
    position: absolute !important;
    left: 0px;
    opacity: 0;
}

input[type="radio"].form-check-input+label.form-check-label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    outline: none;
    /* Prevent default focus outline */
}

input[type="radio"].form-check-input+label.form-check-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #D1D5DC;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="radio"].form-check-input:checked+label.form-check-label::before {
    border-color: #4057E3;
}

input[type="radio"].form-check-input+label.form-check-label::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
}

input[type="radio"].form-check-input:checked+label.form-check-label::after {
    background-color: #4057E3;
}

/* Focus styles for the label */
input[type="radio"].form-check-input:focus+label.form-check-label::before {
    border-color: #4057E3;
}

input[type="radio"].form-check-input:focus+label.form-check-label {
    outline: none;
}

/* file upload box css */

.upload-box {
    margin: 8px auto 0;
    text-align: center;
    border: 1px dashed #4057E3;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.upload-box:hover {
    background-color: #4057E305;
    cursor: pointer;
}

.upload-box #upload-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.upload-box .upload-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #07142D;
}


.upload-box #upload-button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #4057E3;
    text-decoration: underline;
    cursor: pointer;
}

.upload-box #upload-button:hover {
    text-decoration: underline;
}

.uploaded-file-box {
    position: relative;
    margin: 8px 0;
}

.uploaded-file-box .file-name {
    display: block;
    font-size: 13px;
    color: #07142D;
    font-weight: 500;
    margin-bottom: -5px;
}

.uploaded-file-box .file-size {
    font-size: 12px;
}

.uploaded-file-box .close {
    position: absolute;
    right: 0;
    top: 5px;
}

.uploaded-file-box .close img {
    width: 10px;
}

.upload-progress {
    width: 100%;
    border-radius: 4px;
    background-color: #EAEDF0;
    margin: 10px 0;
}

.upload-progress-bar {
    width: 25%;
    border-radius: 4px;
    background-color: #4057E3;
    height: 4px;
}

/* input password css */
.input-password {
    position: relative;
}

.input-password .pass_eye {
    position: absolute;
    right: 10px;
    top: 37px;
    cursor: pointer;
    opacity: 0.2;
}

.input-password .active_pass {
    opacity: 1;
}

/* password-suggestion-box css */
.password-suggestion-box {
    padding: 12px 12px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 4px;
    position: absolute;
    margin-top: 10px;
    z-index: 2;
    font-size: 12px;
    font-weight: 500;
    color: #07142D;
    opacity: 0;
    transition: opacity 0.3s ease;
    visibility: hidden;
}

.input-password input:focus+.pass_eye+.password-suggestion-box {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.password-suggestion-box div {
    margin-bottom: 6px;
}

.password-suggestion-box div:last-child {
    margin-bottom: 0px;
}

.password-suggestion-box img {
    margin-right: 5px;
}

.password-suggestion-box::after {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    z-index: -1;
    top: -5px;
    left: 42%;
    transform: rotate(45deg);
}

/* secondory upload btn */

.fileInput2 {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0;
}

.lm-upload2 {
    padding: 8px 16px;
    border-radius: 4px;
    background: #F0F2FF;
    color: #4057E3;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
    border: unset;
    margin-bottom: 0;
}

.lm-upload2:hover {
    transition: 0.3s;
    cursor: pointer;
    background: #DEE3FF;
}

/* multi select dropdown */

.lm-tag-box {
    border: 1px solid #EAEDF0;
    padding: 6px 12px;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.lm-name-tag {
    background: #8080AE;
    border-radius: 4px;
    padding: 3px 10px;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}

.lm-name-tag .remove-tag {
    margin-left: 10px;
    cursor: pointer;
}

.select__control .select__multi-value {
    background-color: #8080AE;
    border-radius: 4px;
    padding: 3px 0px 3px 10px;
    color: #FFF;
    margin-right: 7px;
}

.select__control .select__multi-value__label {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    padding: 1px;
}

.select__control .select__multi-value__remove:hover {
    background-color: unset;
    color: #e4e4e4;
}

.select__control .select__multi-value__remove svg {
    height: 17px;
    width: 17px;
}

/* .select__control .select__value-container--is-multi{
    flex-wrap: nowrap;
    overflow: auto;
}

.select__control .select__value-container--is-multi div{
    width: max-content;
} */

/* form note box css */
.form-note-box {
    border: 1px solid #EAEDF0;
    background-color: #F0F2FF;
    padding: 16px 24px;
    border-radius: 6px;
    margin-top: 10px;
}

.form-note-box img {
    height: max-content;
    margin-right: 10px;
}

.form-note-box h4 {
    font-size: 16px;
    font-weight: 700;
    color: #07142D;
}

.form-note-box p {
    font-size: 13px;
    font-weight: 500;
    color: #22315D;
}

/* Seacrh bar */
.lm-search-bar {
    position: relative;
}

.lm-search-bar input {
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    padding: 8px 12px 8px 40px;
    font-size: 13px;
    font-weight: 500;
    color: #07142D;
    position: relative;
}

.lm-search-bar:before {
    content: ' ';
    height: 20px;
    width: 20px;
    background-image: url('assets/icons/search-icon.svg');
    position: absolute;
    left: 10px;
    top: 8px;
    z-index: 1;
}

.lm-search-bar input:hover {
    box-shadow: 4px 4px 16px 0px #4057E31A;
    border: 1px solid #4057E3;
    outline: unset;
}

.lm-search-bar input:focus-visible,
.lm-search-bar input:focus {
    box-shadow: unset;
    border: 1px solid #4057E3;
    outline: unset;
}

.lm-button1 {
    padding: 8px 16px;
    border-radius: 4px;
    background: #4057E3;
    color: #F0F2FF;
    font-size: 14px;
    font-weight: 500;
    border: unset;
    transition: 0.3s;
    height: max-content;
}

.lm-button1:hover {
    transition: 0.3s;
    cursor: pointer;
    background: #586EF2;
    color: #F0F2FF;
}

a.lm-button1 {
    color: #FFFFFF;
    width: 100%;
    text-align: center;
}

.lm-button1 img {
    margin-top: -2px;
    margin-right: 5px;
}

.lm-button2 {
    padding: 8px 16px;
    border-radius: 4px;
    background: #F0F2FF;
    color: #4057E3;
    font-size: 14px;
    font-weight: 500;
    border: unset;
    transition: 0.3s;
    height: max-content;
}

.lm-button2:hover {
    transition: 0.3s;
    cursor: pointer;
    background: #DEE3FF;
}

.lm-button2 img {
    margin-top: -2px;
    margin-right: 5px;
}

.lm-button3 {
    display: flex;
    color: #4057E3 !important;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    text-align: right;
}

.lm-button3 span {
    height: 20px;
    width: 20px;
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
    padding: 0px 4px;
    margin-right: 5px;
    background-color: #4057E3;
    box-shadow: 0px 4px 10px 0px #4057E333;
    color: #FFF;
    border-radius: 50%;
}

/* No css button */
.no-css-btn {
    border: unset;
    background-color: unset;
    outline: unset;
    box-shadow: none;
    padding: 0;
}

/* Grid Table */
.grid-head {
    background-color: #FFF;
    padding: 24px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #EAEDF0;
    border-bottom: unset;
}

.grid-head h3 {
    font-size: 18px;
    font-weight: 700;
    color: #07142D;
}

.lm-grid-table {
    width: 100%;
    border: 1px solid #EAEDF0;
    border-radius: 4px;
    margin: 30px 0;
    background-color: #FFF;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;
}

.lm-grid-table input[type=checkbox] {
    margin-top: 0;
}

.lm-grid-table th,
.lm-grid-table td {
    border-bottom: 1px solid #EAEDF0;
}

.lm-grid-table tfoot tr td {
    border-bottom: unset;
}

.lm-grid-table thead {
    background-color: #FFF;
    z-index: 2;
}

.lm-grid-table .lm-angle-up-icon {
    margin-bottom: -2px;
}

.lm-grid-table .lm-angle-down-icon {
    margin-top: 2px;
}

.lm-grid-table th {
    font-size: 12px;
    font-weight: 600;
    color: #99A4B1;
    text-transform: uppercase;
    padding: 26px 16px;
    position: relative;
}

.lm-grid-table th .sort-arrow {
    margin-left: 12px;
    margin-top: 2px;
}

.lm-grid-table td {
    font-size: 13px;
    font-weight: 600;
    color: #07142D;
    padding: 22px 16px;
}

.lm-grid-table tbody tr {
    transition: 0.5s;
}

.lm-grid-table tbody tr:hover {
    background-color: #F7F7F7;
    box-shadow: 0px 2px 8px 0px #0000001A;
    transition: 0.5s;
}

.lm-grid-table tfoot td {
    padding: 15px 16px;
}

.col-head-right {
    margin-left: auto;
    width: max-content;

}

.pointer-cursor {
    cursor: pointer;
}

/* grid pagination css */

.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.page-prev-btn,
.page-next-btn {
    margin-right: 10px;
}

.pagination .page-link {
    font-size: 14PX;
    font-weight: 500;
    color: #999EBF;
    font-family: "Manrope", sans-serif;
    background-color: unset;
    border-color: unset;
    border: unset;
    padding: 0;
    margin: 0 12px;
}

.pagination .page-link:hover {
    color: #4057E3;
}

.page-item.active .page-link {
    z-index: 3;
    color: #4057E3;
    background-color: unset;
    border-color: unset;
    border: unset;
}

.page-link:focus {
    z-index: 3;
    color: #4057E3;
    background-color: unset;
    outline: 0;
    box-shadow: unset;
}

.pagination {
    place-items: center;
}

.pagination .d-flex {
    place-self: center;
}

.pagination-r-tbl .d-flex {
    place-items: center;
}

.pagination-r-tbl #paginationButtons {
    margin: 0 10px;
}

#paginationButtons {
    height: max-content;
    display: flex;
    gap: 10px;
    margin: -8px 10px 0;
}

#paginationButtons div {
    font-size: 14px;
    color: #07142D;
    font-weight: 500;
}

.pagination button {
    background: unset;
    border: unset;
    display: flex;
}


.pagination .page-number-box {
    width: max-content;
    font-size: 14px;
    padding: 8px 4px;
}


.go-to-page-box {
    width: 40px;
    font-size: 14px;
    padding: 8px 4px;
}

.pagination input::-webkit-outer-spin-button,
.pagination input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}


/* inner form grid table css */

.lm-inner-form-grid {
    width: 100%;
    border-top: 1px solid #EAEDF0;
    background-color: #FFF;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;
    border-radius: 0 0 6px 6px;
}

.lm-inner-form-grid th,
.lm-inner-form-grid td {
    border-bottom: 1px solid #EAEDF0;
}

.lm-inner-form-grid tbody tr:last-child th,
.lm-inner-form-grid tbody tr:last-child td {
    border-bottom: unset;
}

.lm-inner-form-grid thead {
    background-color: #FFF;
    z-index: 2;
}

.lm-inner-form-grid .lm-angle-up-icon {
    margin-bottom: -2px;
}

.lm-inner-form-grid .lm-angle-down-icon {
    margin-top: -2px;
}

.lm-inner-form-grid th {
    font-size: 12px;
    font-weight: 600;
    color: #99A4B1;
    text-transform: uppercase;
    padding: 20px 20px;
    position: relative;
}

.lm-inner-form-grid th .sort-arrow {
    margin-left: 15px;
    margin-top: 3px;
}

.lm-inner-form-grid td {
    font-size: 13px;
    font-weight: 600;
    color: #07142D;
    padding: 20px 20px;
    ;
}

.lm-inner-form-grid tbody tr {
    transition: 0.3s;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

@media (max-width:1025px) {
    .lm-grid-table thead {
        position: relative;
        top: unset;
    }

    .lm-grid-table td,
    .lm-inner-form-grid td {
        padding: 16px 16px;
    }
}

@media (max-width:991px) {

    .lm-grid-table,
    .lm-inner-form-grid {
        display: block;
        overflow: auto;
        white-space: nowrap;
    }
}

@media (max-width:1500px) {
    .users-screen .lm-grid-table thead {
        position: relative;
        top: unset;
    }

    .users-screen .lm-grid-table td,
    .users-screen .lm-inner-form-grid td {
        padding: 16px 16px;
    }

    .users-screen .lm-grid-table,
    .users-screen .lm-inner-form-grid {
        display: block;
        overflow: auto;
        white-space: nowrap;
    }
}

/* Active/inactive switch */

.lm-toggle-switch {
    margin: -20px 0 -10px;
}

.lm-toggle-switch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    margin-right: 0;
}

.lm-toggle-switch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 36px;
    height: 22px;
    background: #D1D5DC;
    display: block;
    border-radius: 100px;
    position: relative;
}

.lm-toggle-switch label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.lm-toggle-switch input:checked+label {
    background: #4057E3;
}

.lm-toggle-switch input:checked+label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.lm-toggle-switch label:active:after {
    width: 24px;
}

.lm-grid-edit-icon {
    cursor: pointer;
}

.lm-grid-edit-icon:hover {
    cursor: pointer;
    filter: brightness(0.5);
}

/* lm-common tooltip css can use globally */

.lm-tooltip {
    position: relative;
}

.lm-tooltip img {
    width: 14px;
    filter: grayscale(1);
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s;
}

.lm-tooltip img:hover {
    filter: unset;
    opacity: 1;
    transition: 0.3s;
}

.lm-grid-delete-icon:hover {
    cursor: pointer;
}

.lm-tooltip:after,
.lm-tooltip:before {
    opacity: 0;
    pointer-events: none;
    bottom: 101%;
    left: 50%;
    position: absolute;
    z-index: 10;
    transform: translate(-50%, 10px);
    transform-origin: top;
    transition: all 0.18s ease-out 0.18s;
}

.lm-tooltip:after {
    background-color: #07142D;
    box-shadow: 2px 2px 32px 0px #2E373724;
    content: attr(data-tooltip);
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 6px;
    z-index: 99;
    text-transform: none;
}

.lm-tooltip:before {
    width: 0;
    height: 0;
    content: "";
    margin-bottom: 6px;
}

.lm-tooltip:hover:before,
.lm-tooltip:hover:after {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, 0);
}

[data-tooltip-pos="up"]:before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #07142D;
    margin-bottom: 0px;
}

[data-tooltip-pos="down"]:before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #07142D;
    margin-top: 6px;
}

[data-tooltip-pos="right"]:before {
    border-top: 6px solid transparent;
    border-right: 6px solid #07142D;
    border-bottom: 6px solid transparent;
    margin-left: 6px;
}

[data-tooltip-pos="left"]:before {
    border-top: 6px solid transparent;
    border-left: 6px solid #07142D;
    border-bottom: 6px solid transparent;
    margin-right: 6px;
}

[data-tooltip-pos="down"]:before,
[data-tooltip-pos="down"]:after {
    bottom: auto;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -10px);
}

[data-tooltip-pos="down"]:after {
    margin-top: 11px;
}

[data-tooltip-pos="down"]:hover:before,
[data-tooltip-pos="down"]:hover:after {
    transform: translate(-50%, 0);
}

[data-tooltip-pos="left"]:before,
[data-tooltip-pos="left"]:after {
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translate(10px, -50%);
}

[data-tooltip-pos="left"]:after {
    margin-right: 11px;
}

[data-tooltip-pos="left"]:hover:before,
[data-tooltip-pos="left"]:hover:after {
    transform: translate(0, -50%);
}

[data-tooltip-pos="right"]:before,
[data-tooltip-pos="right"]:after {
    bottom: auto;
    left: 100%;
    top: 50%;
    transform: translate(-10px, -50%);
}

[data-tooltip-pos="right"]:after {
    margin-left: 11px;
}

[data-tooltip-pos="right"]:hover:before,
[data-tooltip-pos="right"]:hover:after {
    transform: translate(0, -50%);
}

[data-tooltip-length]:after {
    white-space: normal;
}

[data-tooltip-length="small"]:after {
    width: 80px;
}

[data-tooltip-length="medium"]:after {
    width: 150px;
}

[data-tooltip-length="large"]:after {
    width: 260px;
}

[data-tooltip-length="fit"]:after {
    width: max-content;
}

[data-tooltip-length="cutsom"]:after {
    min-width: 166px;
    max-width: 272px;
    width: max-content;
}

.small-tooltip .lm-tooltip img {
    width: 10px;
}

.small-tooltip {
    display: flex;
}

.small-tooltip .lm-tooltip {
    margin: -6px 0 0 5px;
}

.big-tooltip {
    position: relative;
    display: inline-block;
    margin-left: 6px;
}

.big-tooltip img {
    width: 14px;
    filter: grayscale(1);
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s;
}

.big-tooltip img:hover {
    filter: grayscale(0);
    opacity: 1;
}

.big-tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: #07142D;
    color: #fff;
    text-align: left;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    position: absolute;
    z-index: 1;
    top: -4rem;
    left: 25px;
    transition: 0.3s;
}

.big-tooltip .tooltiptext::before {
    width: 0;
    height: 0;
    position: absolute;
    left: -12px;
    top: 4.2rem;
    content: "";
    border-top: 6px solid transparent;
    border-right: 6px solid #07142D;
    border-bottom: 6px solid transparent;
    margin-left: 6px;
}

.big-tooltip:hover .tooltiptext {
    visibility: visible;
    transition: 0.3s;
}

@media (max-width: 575px){
    .big-tooltip .tooltiptext{
        left: -100px;
        top: -12.8rem;
        width: 280px;
    }

    .big-tooltip .tooltiptext::before{
        left: 98px;
        top: unset;
        bottom: -8px;
        transform: rotate(270deg);
    }
}

/* css till 19/03/2024 */

/* header progres bar */

.lm-progress-bar {
    height: 4px;
    background: #EAEDF0;
    width: 100%;

    position: fixed;
    top: 0px;
    left: 0;
    z-index: 99;
    margin-left: 265px;
    margin-top: 68px;
}

.lm-progress {
    background: #4057E3;
    border-radius: 0 4px 4px 0;
    height: 4px;
    width: 15%;
}

@media (max-width:1024px) {
    .lm-progress-bar {
        margin-left: 0;
    }
}

@media (max-width:575px) {
    .lm-progress-bar {
        top: -3px;
    }
}

.lm-breadcrumb .text-light {
    font-size: 14px;
    font-weight: 500;
    transition: 0.5s;
}

.lm-breadcrumb .text-light:hover {
    color: #07142D !important;
    transition: 0.5s;
}

.lm-breadcrumb .text-dark {
    font-size: 14px;
    font-weight: 500;
}

.back-btn {
    margin-top: -2px;
}

.status {
    padding: 4px 10px;
    height: max-content;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    width: max-content;
}

.status.new {
    background: #DAF4E8;
    color: #12B76A;
}

.status .closed {
    background: #FFE4E4;
    color: #FD151B;
}

.status .in-progress {
    background: #FFE9DC;
    color: #EE7930;
}

.status .review-required {
    background: #F0EAFF;
    color: #6941C6;
}

.status.required {
    background: #EEF4FF !important;
    color: #055FFC !important;
}

/* slide right effect on modal */
.modal.fade-right .modal-dialog {
    transition: transform 0.4s ease-out;
    transform: translateX(100%);
}

.modal.fade-right.show .modal-dialog {
    transform: translateX(0);
}

/* common right side drawer form css */
.drawer-modal .modal-dialog {
    margin: 0;
    position: fixed;
    right: 0;
}

.drawer-modal .modal-content {
    width: 450px;
    height: 100vh;
    border-radius: 0;
}

.drawer-head {
    background-color: #F3F4F6;
    padding: 16px;
    position: fixed;
    width: inherit;
    place-items: center;
    top: 0;
    z-index: 2;
}

.drawer-head h3 {
    font-size: 16px;
    font-weight: 700;
    color: #07142D;
}

.drawer-body {
    padding: 72px 16px;
    overflow: auto;
}

.drawer-footer {
    background-color: #fff;
    padding: 16px;
    position: fixed;
    width: 100%;
    place-items: center;
    bottom: 0;
    z-index: 2;
    border-top: 1px solid #EAEDF0
}

.drawer-modal ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 8px;
}

.drawer-modal ::-webkit-scrollbar-track {
    background: #ffffff00;
}

.drawer-modal ::-webkit-scrollbar-thumb {
    background: #D1D5DC;
}

.drawer-modal ::-webkit-scrollbar-thumb:hover {
    background: #D1D5DC;
}

.add-notes-drawer textarea {
    height: 95px !important;
}

.add-notes-drawer .drawer-body {
    padding: 72px 16px 190px;
}

.add-notes-drawer .drawer-head {
    background-color: #ffffff;
    border-bottom: 1px solid #EAEDF0;
}

@media (min-width: 1500px) {

    /* CSS to re-enable scrollbars for .my-component */
    .dashboard-wrap::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    * {
        scrollbar-width: none;
    }

}

@media (max-width: 575px) {
    .drawer-modal .modal-content {
        width: 100vw;
    }
}



/* lm commons nav tabs css */

.lm-nav-tabs {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAEDF0;
}

.lm-nav-tabs .nav-tabs {
    border-bottom: unset;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}

.lm-nav-tabs .nav-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
}


.lm-nav-tabs .nav-tabs .nav-link {
    border: unset;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #99A4B1;
    cursor: pointer;
    padding: 10px 0 10px;
    margin: 0 10px;
    display: flex;
    width: max-content;
    transition: 0.3s;
}

.lm-nav-tabs .nav-tabs .nav-item:first-child .nav-link {
    margin-left: 0;
}

.lm-nav-tabs .nav-tabs .nav-item:last-child .nav-link {
    margin-right: 0;
}

.lm-nav-tabs .nav-tabs .nav-link:focus,
.lm-nav-tabs .nav-tabs .nav-link:hover {
    border: unset;
    color: #22315D;
    transition: 0.3s;
}

.lm-nav-tabs .nav-tabs .nav-link.active {
    color: #22315D;
    border-bottom: 4px solid #4057E3;
}

.lm-nav-tabs .note-count {
    display: none;
}

.nav-tabs .active .note-count {
    display: block;
    background: #12B76A;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    padding: 2px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-left: 4px;
}

/* lm single select tabs */
.radio-tabs {
    border-bottom: unset;
}

.radio-tabs .nav-tabs {
    border: 1px solid #EAEDF0;
    border-radius: 6px;
    padding: 4px;
}

.radio-tabs .nav-tabs .nav-link {
    width: 100%;
    text-align: center;
    margin: 0;
    justify-content: center;
    padding: 5px 0;
}

.radio-tabs .nav-tabs .nav-link.active {
    color: #FFFFFF;
    border-bottom: unset;
    background-color: #4057E3;
    border-radius: 4px;
}

.radio-tabs .nav-item {
    width: 50%;
}

@media (min-width:1200px) {
    .pfs-radio-tabs {
        width: 60%;
    }
}

/* dashboard-screen css */
.dashboard-screen {
    background-color: #FFFFFF;
    padding: 20px;
    overflow: hidden;
}

.dashboard-screen .card-row {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-bottom: 20px;
}

.db-card {
    border: 1px solid #EAEDF0;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 16px 24px;
    margin-bottom: 20px;
}

.dashboard-screen .db-sm-card {
    padding: 10px 18px 14px;
    margin-bottom: 0px;
}

.dashboard-screen .db-sm-card-title {
    color: #99A4B1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.dashboard-screen .db-sm-card-value {
    margin-top: 5px;
    color: #07142D;
    font-size: 18px;
    font-weight: 700;
}

.dashboard-screen .db-card-title {
    color: #07142D;
    font-size: 14px;
    font-weight: 700;
}

.dashboard-screen .db-card .cust-date-dp .select__control {
    min-width: 160px;
}

.dashboard-screen .year-selector {
    margin-left: 20px;
    border: 1px solid #EAEDF0;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    place-items: center;
}

.dashboard-screen .year-selector button {
    border: none;
    background-color: unset;
    transition: 0.3s;
    height: 100%;
}

.dashboard-screen .year-selector button:first-child {
    padding: 6px 4px 6px 10px;
    border-right: 1px solid #EAEDF0;
}

.dashboard-screen .year-selector button:last-child {
    padding: 6px 10px 6px 4px;
    border-left: 1px solid #EAEDF0;
}

.dashboard-screen .year-selector button:hover {
    background-color: #F7F7F7;
    transition: 0.3s;
}

.dashboard-screen .year-selector .year {
    color: #07142D;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
}

.dashboard-screen .year-selector .lm-angle-left-icon,
.dashboard-screen .year-selector .lm-angle-right-icon {
    margin: 7px;
}

.dashboard-screen .db-card .graph {
    margin-top: 20px;
}

.dashboard-screen .db-card .graph img {
    width: 100%;
}

.dashboard-screen .db-card .map {
    margin-top: 20px;
}

.dashboard-screen .db-card .map img {
    width: 100%;
}

.dashboard-screen .db-card .ScroeCard {
    margin-top: 20px;
}

.dashboard-screen .db-card .ScroeCard img {
    width: 100%;
}

.market-comparison-filters .select__control {
    width: 100%;
    margin-bottom: 30px;
}

.cust-bar-label {
    font-size: 13px;
    font-weight: 500;
    color: #99A4B1;
}

.cust-bar .ratio-bar {
    height: 24px;
    border-radius: 2px;
    width: 80%;
}

.cust-bar.lm-bar .ratio-bar {
    background-color: #4057E3;
}

.cust-bar.standard-bar .ratio-bar {
    background-color: #D6DCFF;
}

.db-pie-chart {
    margin-top: 15px;
}

.db-card hr {
    border-top: 1px solid #EAEDF0;
    margin: 23px 0;
}

.db-table {
    margin-top: 0;
}

.db-table tbody {
    display: block;
    height: 196px;
    overflow: auto;
    width: 100%;
}

.db-table thead,
.db-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
}

.db-table ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 8px;
}

.db-table ::-webkit-scrollbar-track {
    background: #ffffff00;
}

.db-table ::-webkit-scrollbar-thumb {
    background: #D1D5DC;
}

.db-table ::-webkit-scrollbar-thumb:hover {
    background: #D1D5DC;
}

.db-table .application_no {
    white-space: break-spaces;
}

.application_noColor {
    color: red;
}

.db-table th {
    padding: 20px 16px;
}

.db-table td {
    padding: 13px 16px;
}

.db-table tr:last-child td {
    border-bottom: unset;
}

.db-table tr th:nth-child(1) {
    width: 40%;
}

.db-table tr td:nth-child(1) {
    width: 40%;
}

.db-table tr th:nth-child(2) {
    width: 20%;
}

.db-table tr td:nth-child(2) {
    width: 20%;
}

.db-table tr th:nth-child(3) {
    width: 40%;
}

.db-table tr td:nth-child(3) {
    width: 40%;
}

.dashboard-screen .amortization-table .lm-grid-table {
    margin-top: 0;
}

.dashboard-screen .grid-head {
    padding: 20px 16px;
}

.dashboard-screen .lm-grid-table {
    border-radius: 0px;
}

.dashboard-screen .db-custom-calender {
    padding: 0;
}

.dashboard-screen .db-custom-calender .react-datepicker {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: 0;
    width: 100%;
}

.dashboard-screen .db-custom-calender .react-datepicker__month-container {
    float: left;
    width: 100%;
}

.dashboard-screen .db-custom-calender .react-datepicker__day {
    margin: 0rem;
    line-height: 32px;
    width: 70%;
    height: 34.5px;
    border-right: unset;
    border-bottom: unset;
}

.dashboard-screen .db-custom-calender .react-datepicker__current-month {
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #EAEDF0;
    background-color: #F0F2FF;
    margin-top: 0;
    padding: 14px 0;
}

.dashboard-screen .db-custom-calender .react-datepicker__navigation--previous,
.dashboard-screen .db-custom-calender .react-datepicker__navigation--next {
    top: 14px;
}

.db-custom-calender .react-datepicker__month .react-datepicker__week:first-child {
    padding-top: 10px;
}

.dashboard-screen .db-custom-calender .react-datepicker__month .react-datepicker__week {
    display: grid;
    grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
}

.dashboard-screen .db-custom-calender .react-datepicker__day-names {
    display: grid;
    grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
    padding-left: 18px;
}

.dashboard-screen .db-custom-calender .react-datepicker__day-name {
    width: 70%;
    margin: 0 0 5px;
}

.dashboard-screen .db-custom-calender .react-datepicker__month {
    margin: 0;
    padding-left: 18px;
}

.dashboard-screen .db-custom-calender .react-datepicker__month .react-datepicker__week:first-child .react-datepicker__day {
    border-top: unset;
}

.dashboard-screen .db-custom-calender .react-datepicker__month .react-datepicker__week .react-datepicker__day:first-child {
    border-left: unset;
}

.dashboard-screen .db-custom-calender .react-datepicker__day--selected,
.dashboard-screen .db-custom-calender .react-datepicker__day--selected:hover {
    color: #FFFFFF;
    border-radius: 0;
    background-color: #4057E3;
}

.no-data-card {
    min-height: calc(100vh - 136px);
}
.no-data-card img {
    width: 15%;
}

.react-datepicker__day--in-range {
    background-color: #F0F2FF;
}

@media (max-width:1024px) {
    .dashboard-screen .card-row {
        display: grid;
        grid-template-columns: auto auto;
    }

    .dashboard-screen {
        padding: 10px;
    }
}

@media (max-width:575px) {
    .dashboard-screen .card-row {
        display: grid;
        grid-template-columns: auto auto;
    }

    .dashboard-screen .db-sm-card-title {
        font-size: 11px;
    }

    .dashboard-screen .db-card {
        padding: 16px 16px;
    }

    .dashboard-screen .db-sm-card {
        padding: 10px 12px 12px;
    }

    .closed-loans-head {
        display: block !important;
    }

    .closed-loans-head .db-card-title {
        margin-bottom: 20px;
    }

}

/* Inquiries & Tempaltes & logs page css */

.inquiries-wrap,
.logs-wrap,
.templates-wrap {
    background-color: #FFFFFF;
    padding: 20px;
    min-height: calc(100vh - 68px);
}

.inquiries-table .msg-col {
    width: 40%;
}

.inquiries-table tbody tr:nth-child(odd),
.logs-table tbody tr:nth-child(odd) {
    background-color: #F7F7F7;
}

.templates-wrap .lm-select {
    width: 220px;
}

.templates-wrap .lm-search-bar input {
    width: 360px;
}

.templates-wrap .modal-footer {
    gap: 15px;
}

.templates-wrap .modal-footer button {
    width: 100%;
}

.logs-wrap .lm-search-bar input {
    width: 190px;
}

.logs-wrap .input-date::after {
    top: 8px;
}

@media (min-width:1024px) {
    .logs-wrap .select__control {
        min-width: 168px;
        margin-right: 15px;
    }

}


@media (max-width:575px) {
    .logs-filter-dropdown {
        flex-wrap: wrap;
        gap: 15px;
    }

    .logs-filter-dropdown .lm-select {
        width: max-content;
    }

    .logs-wrap .lm-search-bar,
    .logs-wrap .lm-search-bar input {
        width: 100%;
    }

}

@media (max-width:991px) {
    .logs-wrap .filter-search-group {
        flex-wrap: wrap;
        gap: 20px;
    }

    .logs-wrap .search-date-grp .input-date {
        width: 100%;
        margin-bottom: 15px;
    }

    .logs-wrap .search-date-grp {
        flex-wrap: wrap;
    }
}

@media (max-width:1024px) {

    .inquiries-wrap,
    .templates-wrap {
        padding: 10px;
        margin-top: -3px;
    }

    .inquiries-table {
        white-space: unset;
    }

    .msg-content {
        width: 300px;
    }

    .templates-wrap .filter-search-group .lm-select,
    .templates-wrap .filter-search-group .lm-search-bar {
        width: 48%;
    }

    .templates-wrap .filter-search-group .lm-search-bar input {
        width: 100%;
    }
}


/* lm card css */
.lm-card {
    border: 1px solid #EAEDF0;
    border-radius: 6px;
    padding: 20px 24px;
    background-color: #FFFFFF;
}

.lm-form-box .lm-card {
    margin-bottom: 20px;
}

.lm-card-heading {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #07142D;
}

@media (max-width:575px) {
    .lm-card {
        padding: 18px 16px;
    }

    .lm-card-heading {
        font-size: 16px;
    }
}


/* common setting page css */

.setting-profile-sec {
    display: flex;
    justify-content: space-between;
    place-items: center;
}

.setting-profile-sec .d-flex {
    place-items: center;
}

.password-match-toast {
    border: 1px solid #FFBFC1;
    background: #FFE9EA;
    color: #FD151B;
    border-radius: 6px;
    padding: 6px 12px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 600;
}

.setting-profile-sec .profile-img img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    object-position: top;
    border-radius: 100%;
}

.setting-profile-sec .profile-img {
    position: relative;
}

.setting-profile-sec .profile-img .edit-btn {
    position: absolute;
    background-color: #22315D;
    border: #22315D 1px solid;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    top: 50px;
    right: 0px;
}

.setting-profile-sec .profile-img .edit-btn img {
    color: white;
    width: 24px;
    height: 24px;
    background-color: #22315D;
    border: #22315D 1px solid;
    border-radius: 50%;
    display: block;
}

.setting-profile-sec .profile-img .delete-btn {
    position: absolute;
    background-color: #ffffff;
    border: #ffffff 1px solid;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    top: 0px;
    right: 0px;
}

.setting-profile-sec .profile-img .delete-btn img {
    color: white;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border: #ffffff 1px solid;
    border-radius: 50%;
    display: block;
}


.setting-profile-sec .profile-text-img h2 {
    font-size: 16px;
    font-weight: 500;
    color: #07142D;
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 100%;
    background-color: #F3F4F6;
    padding: 30px 0;
}

.notification-setting {
    padding: 20px 0;
    border-bottom: 1px solid #EAEDF0;
    gap: 10px;
}

.notification-setting:last-child {
    border-bottom: unset;
}

.notification-setting .lm-toggle-switch {
    margin: -25px 0 -20px;
}

.link-box {
    border: 1px solid #EAEDF0;
    background-color: #F3F4F6;
    border-radius: 6px;
    padding: 10px;
    font-size: 13px;
    color: #07142D;
    font-weight: 600;
    gap: 10px;
}

@media (max-width:575px) {
    .setting-profile-sec {
        display: block;
    }

    .setting-profile-sec .d-flex {
        margin-bottom: 20px;
    }
}

/* bottom sticky button css */

.sticky-btn-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    padding: 12px 24px;
    border-top: 1px solid #EAEDF0;
    box-shadow: 0px -8px 16px 0px #0000000F;
    z-index: 99;
    width: calc(100% - 265px);
    margin-left: 265px;
}

@media (max-width: 1024px) {
    .sticky-btn-footer {
        width: 100%;
        margin-left: 0;
        padding: 12px 16px;
    }
}

/* common toaser css */

/* Keyframes for fade in down animation */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Keyframes for fade out up animation */
@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.fadeInDown {
    animation: fadeInDown 0.5s forwards;
}

.fadeOutUp {
    animation: fadeOutUp 0.5s forwards;
}

.lm-toaster {
    z-index: 99999999;
    width: calc(100% - 265px);
    margin-left: 265px;
    position: fixed;
    top: 20px;
    display: flex;
    transform: translateY(-30px);
    transition: 0.5s;
    opacity: 0;
}

.website-main .lm-toaster {
    width: calc(100% - 0px);
    margin-left: 0px;
    top: 40px;
}

.lm-toaster-body {
    background-color: #293450;
    border-radius: 6px;
    padding: 8px 12px 8px 40px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    width: max-content;
    margin: 0 auto;
    background-image: url('assets/icons/toaster_icon.svg');
    background-size: 20px;
    background-position: 10px;
    background-repeat: no-repeat;
    display: flex;
}

.error .lm-toaster-body {
    background-image: url('assets/icons/pfs_card_cross.svg');
}

.toaster-visible {
    transform: translateY(0px);
    transition: 0.5s;
    opacity: 1;
}

.lm-toaster button {
    border: unset;
    outline: none;
    background-color: transparent;
    padding: 0 0 0 10px;
}

.lm-toaster button img {
    width: 20px;
}

@media (min-width: 575px) and (max-width: 1025px) {
    .lm-toaster {
        width: calc(100% - 0px);
        margin-left: 0px;
        top: 40px;
    }
}

@media (max-width: 575px) {
    .lm-toaster {
        width: calc(100% - 0px);
        margin-left: 0px;
        top: 40px;
    }
}


/* lm common divider to break the section */

.lm-divider {
    border-bottom: 1px solid #EAEDF0;
    margin-bottom: 1rem;
    width: 100%;
}

/* preloader css start*/
.preloader {
    height: 100vh;
    width: 100vw;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #07142d78;
}

.loader-obj {
    width: 50px;
    margin-bottom: 15px;
}

.loader-box {
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    box-shadow: 0px 8px 16px -4px #1018280A;
    background: #FFFFFF;
    padding: 24px;
    border-radius: 6px;
}

.website-main .loader-box {
    left: 43%;
}

.loader-box h4 {
    font-size: 14px;
    font-weight: 700;
}

@media (min-width:991px) and (max-width:1025px) {
    .loader-box {
        position: fixed;
        left: 38%;
        top: 45%;
    }
}

@media (min-width:575px) and (max-width:991px) {
    .loader-box {
        position: fixed;
        left: 36%;
        top: 40%;
    }
}

@media (max-width:575px) {
    .loader-box {
        position: fixed;
        left: 22%;
        top: 40%;
    }
    .website-main .loader-box {
        left: 24%;
    }
}

/* Angle/Arrow icons */

.lm-angle-down-icon {
    height: 5px;
    width: 5px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #99A4B1;
    transform: rotate(45deg);
}

.lm-angle-down-icon:hover {
    border-color: #22315D;
    cursor: pointer;
}

.lm-angle-up-icon {
    height: 5px;
    width: 5px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #99A4B1;
    transform: rotate(225deg);
}

.lm-angle-up-icon:hover {
    border-color: #22315D;
    cursor: pointer;
}

.lm-angle-left-icon {
    height: 7px;
    width: 7px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: #22315D;
    transform: rotate(135deg);
}

.lm-angle-left-icon:hover {
    border-color: #22315D;
    cursor: pointer;
}

.lm-angle-right-icon {
    height: 7px;
    width: 7px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: #22315D;
    transform: rotate(315deg);
}

.lm-angle-right-icon:hover {
    border-color: #22315D;
    cursor: pointer;
}


.error-message {
    font-size: 10px;
    font-weight: 500;
    color: #FF204E !important;
    overflow: show;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0.5s, opacity 0.5s ease-in-out, visibility 0.5s;
}



.error-message:not(:empty) {
    height: max-content;
    /* Enough space for one line of text */
    opacity: 1;
    visibility: visible;
}

.error-message-user {
    font-size: 12px;
    font-weight: 500;
    color: #FF204E !important;
    overflow: show;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0.5s, opacity 0.5s ease-in-out, visibility 0.5s;
}

.error-message-user:not(:empty) {
    height: max-content;
    /* Enough space for one line of text */
    opacity: 1;
    visibility: visible;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.small-text {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.extra-small-text {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.active-page .text-secondary {
    font-weight: bold;
    color: rgb(0, 0, 0) !important;
    /* Change to your desired highlight color */
    border-bottom: 2px solid rgb(0, 0, 0);
    /* Optional, for additional highlighting */
}

/* AI_btn_field */
.AI_btn_field {
    position: relative;
}

.AI_btn_field input {
    padding-right: 100px;
}

.lm-ai-btn {
    background: #22315D;
    padding: 4.6px 8px;
    border-radius: 6px;
    border: unset;
    position: absolute;
    right: 5px;
    top: 32px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    display: flex;
    place-items: center;
}

.lm-ai-btn-no-label {
    background: #22315D;
    padding: 4.6px 8px;
    border-radius: 6px;
    border: unset;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    display: flex;
    place-items: center;
}

.lm-ai-btn-bottom {
    background: #22315D;
    padding: 4.6px 8px;
    border-radius: 6px;
    border: unset;
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    display: flex;
    place-items: center;
}

.lm-ai-btn img,
.lm-ai-btn-bottom img,
.lm-ai-btn-no-label img {
    margin-right: 5px;
}

.ai-update-content {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.lm-ai-update-btn {
    background: #F0F2FF;
    padding: 4.6px 8px;
    border-radius: 6px;
    border: unset;
    font-size: 12px;
    font-weight: 600;
    color: #0F1828;
    gap: 5px;
    display: flex;
    place-items: center;
}

.ai_update_options {
    width: max-content;
    padding: 8px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 6px;
    background-color: #fff;
    position: absolute;
    bottom: auto;
    right: auto;
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 12;
    transition: opacity 0.3s, visibility 0.3s;
    margin-left: 10px;
}

.ai_update_options.ai_update_pose_left{
    transform: translate(0, 50%);
    left: auto;
    top: auto;
    bottom: 50%;
    right: 100%;
    margin-right: 10px;
    margin-left: 0;
}

/* .ai-update-box:hover .ai_update_options {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
} */

.ai_update_options::after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #FFFFFF;
    border-bottom: 12px solid transparent;
    position: absolute;
    bottom: auto;
    right: auto;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: -10px;
}

.ai_update_pose_left.ai_update_options::after{
    border-bottom: 12px solid transparent;
    border-right: 12px solid #FFFFFF;
    border-top: 12px solid transparent;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);
    margin-right: -10px;
}

.ai_update_options div {
    padding: 6px 10px;
    transition: 0.3s;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}


.ai_update_options div img {
    margin-right: 4px;
}

.ai_update_options div:hover img {
    filter: brightness(0);
    transition: 0.3s;
    margin-right: 4px;
}

.ai_update_options div:hover {
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 6px;
    transition: 0.3s;
}

@media (max-width: 575px) {
    .ai_update_options {
        right: 100%;
        left: auto;
        margin-right: 10px;
    }

    .ai_update_options::after {
        border-top: 12px solid transparent;
        border-left: 12px solid #FFFFFF;
        border-bottom: 12px solid transparent;
        border-right: 0;
        right: 0px;
        left: auto;
        margin-right: -10px;
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.is_generating img {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.operation-weeks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}

@media (min-width:1366px) {
    .operation-weeks {
        grid-template-columns: auto auto auto auto auto;
    }
}

@media (min-width:575px) and (max-width:1080px) {
    .operation-weeks {
        grid-template-columns: auto auto auto;
    }
}

@media (max-width:575px) {
    .operation-weeks {
        grid-template-columns: auto;
    }
}

.timepicker-box {
    border-radius: 4px;
    padding: 5px 15px;
    width: max-content;
}

.operation-weeks .operation-day {
    margin-bottom: 20px;
}

.operation-weeks .day-label {
    font-size: 13px;
}

.clear-time-button {
    color: #FD151B;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.clear-time-button:hover {
    color: #FD151B;
}

.operation-weeks .operation-day .dropdown-toggle {
    background: unset;
    border: unset;
    padding: 0;
}

.operation-weeks .dropdown-menu {
    box-shadow: 0px 8px 16px -4px #1018280A;
    border: 1px solid #EAEDF0;
    border-radius: 4px;
}

.operation-weeks .operation-day .dropdown-toggle::after {
    display: none;
}

.time-range-picker {
    padding-right: 30px;
    background-image: url('../src/assets/icons/time-icon.svg');
    background-repeat: no-repeat;
    background-position: 95% 8px;
}

.timepicker-box .basic-single .select__menu {
    min-width: max-content;
}

.timepicker-box .basic-single .select__menu-list {
    max-height: 160px;
}

/* loader.css */
.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey background */
    border-top: 8px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  