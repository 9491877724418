.main-footer {
    padding-top: 55px;
    font-family: "Manrope", sans-serif;
}

.main-footer .container {
    max-width: 1440px;
    margin: auto;
    padding: 0px 82px;
}

.main-footer .extrabold18 {
    color: #07142D;
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
}

@media only screen and (max-width: 767px) {
    .main-footer .container {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 991px) {
    .main-footer {
        padding-top: 56px;
    }
}

.main-footer .footer_top {
    display: flex;
    justify-content: space-between;
    padding: 0 190px 55px 82px;
}

.main-footer .btn_app {
    background: #22315D;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: .3s all;
}

.main-footer .btn_social {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #22315D;
    border-radius: 4px;
    transition: all .3s;
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_top {
        flex-direction: column;
        gap: 32px;
        padding: 0 20px 32px;
    }
}

.main-footer .footer_top_info img {
    margin-bottom: 16px;
}

.main-footer .footer_top_info p {
    color: #22315D;
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.14px;
    margin: 0;
}

.main-footer .footer_top_menu h3 {
    margin: 0 0 20px;
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_top_menu h3 {
        margin: 0 0 16px;
    }
}

.main-footer .footer_top_menu ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.main-footer .footer_top_menu ul li a {
    color: #22315D;
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
    transition: all 0.3s;
}

.main-footer .footer_top_menu ul li a:hover {
    color: #4057E3;
    transition: all 0.3s;
}

.main-footer .footer_bottom {
    padding: 32px 0;
    border-top: 1px solid var(--Grey-200, #EFEFF4);
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom {
        padding: 32px 0 66px;
    }
}

.main-footer .footer_bottom_wrapper {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1400px) {
    .main-footer .footer_bottom_wrapper {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom_wrapper {
        flex-direction: column;
        gap: 32px;
    }
}

.main-footer .footer_bottom_wrapper_app {
    display: flex;
    gap: 72px;
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom_wrapper_app {
        justify-content: space-between;
        width: 100%;
        gap: 0;
    }
}

.main-footer .footer_bottom_wrapper_app_block {
    display: flex;
    gap: 12px;
}

.main-footer .footer_bottom_wrapper_app_block p {
    margin-bottom: 12px;
    color: #07142D;
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    max-width: 36px;
    margin: 0;
}

.main-footer .footer_bottom_wrapper_app_block_link {
    display: flex;
    gap: 12px;
}

@media only screen and (min-width: 1400px) {
    .main-footer .footer_bottom_wrapper_privacy {
        padding: 0 280px 0 226px;
    }
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom_wrapper_privacy {
        text-align: center;
        margin: 0 auto;
        padding: inherit;
    }
}

.main-footer .footer_bottom_wrapper_privacy a {
    color: #07142D;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.16px;
    border-bottom: 1px solid #07142D;
    text-decoration: none;
    transition: all 0.3s;
}

.main-footer .footer_bottom_wrapper_privacy a:hover {
    color: #4057E3;
    transition: all 0.3s;
    border-color: #4057E3;
}

.main-footer .footer_bottom_wrapper_social {
    display: flex;
    gap: 12px;
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom_wrapper_social {
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (max-width: 991px) {
    .main-footer .footer_bottom {
        flex-direction: column;
    }
}