.loan-app-screen {
    background-color: #FFFFFF;
    padding: 20px;
}

@media (max-width:1024px) {
    .loan-app-screen {
        padding: 10px;
    }
}

.loan-app-screen ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 8px;
}

.loan-app-screen ::-webkit-scrollbar-track {
    background: #ffffff00;
}

.loan-app-screen ::-webkit-scrollbar-thumb {
    background: #D1D5DC;
}

.loan-app-screen ::-webkit-scrollbar-thumb:hover {
    background: #D1D5DC;
}

.loan-app-screen .lm-grid-table tr th:first-child, .loan-app-screen .lm-grid-table tr td:first-child{
    width: 200px;
}

.loan-app-screen .lm-grid-table tr th{
    padding: 20px 14px;
}

.loan-app-screen .lm-grid-table tr td{
    padding: 12px 14px;
}


.loan-app-screen .lm-select {
    width: 220px;
}

.loan-app-screen .filter-search-group .select__control{
    width: 220px;
}

.loan-app-screen .lm-search-bar input {
    width: 360px;
}

.loan-app-screen .application_no {
    font-size: 14px;
    font-weight: 700;
}

.loan-app-screen .review-required .application_no {
    color: #FD151B;
}

.loan-app-screen .lm-grid-table tr td:nth-child(2), .loan-app-screen .lm-grid-table tr th:nth-child(2){
    width: 200px;
}

.loan-app-screen .review-required .business_name,
.loan-app-screen .review-required .borrower {
    color: #99A4B1;
}

.loan-app-screen .created_date {
    font-size: 12px;
}

.loan-app-screen .advisor,
.loan-app-screen .partner,
.loan-app-screen .lender,
.loan-app-screen .add_partner {
    text-decoration: underline;
}

.loan-app-screen .status {
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    width: max-content;
}

.loan-app-screen .new .status{
    background: #DAF4E8;
    color: #12B76A;
}

.loan-app-screen .closed .status{
    background: #FFE4E4;
    color: #FD151B;
}

.loan-app-screen .in-progress .status {
    background: #FFE9DC;
    color: #EE7930;
}

.loan-app-screen .review-required .status {
    background: #F0EAFF;
    color: #6941C6;
}

.loan-app-screen .loan_amount {
    text-align: right;
}

.option_dots {
    font-size: 22px;
    line-height: 12px;
    color: #99A4B1;
}

.option_dots:hover {
    color: #07142D;
    cursor: pointer;
}

.options_btn {
    position: relative;
}

.app_options {
    opacity: 0;
    visibility: hidden;
    width: max-content;
    padding: 8px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 6px;
    background-color: #fff;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 12;
    transition: opacity 0.3s, visibility 0.3s;
    margin-right: 10px;
}

.options_btn:hover .app_options {
    opacity: 1; 
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
}


.app_options::after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 12px solid #FFFFFF;
    border-bottom: 12px solid transparent;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: -10px;
}

.app_options div {
    padding: 6px 10px;
    transition: 0.3s;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}

.app_options div:hover img {
    filter: brightness(0);
    transition: 0.3s;
}

.app_options div:hover {
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 6px;
    transition: 0.3s;
}

.app_options hr{
    margin: 8px -8px;
    border-top: 1px solid #EAEDF0;
}

.la-app-modal-footer {
    gap: 15px;
    flex-wrap: nowrap;
}

.la-app-modal-footer button {
    width: 100%;
}

.loan-app-screen .lm-tooltip {
    width: 13px;
    margin-left: 5px;
}

.assign-mem-popup .partner-item {
    padding: 12px 24px;
    border: 1px solid #EAEDF0;
    background-color: #F7F7F7;
    border-radius: 6px;
    margin-bottom: 14px;
}

.assign-mem-popup .partner-item .partner-img img {
    height: 44px;
    width: 44px;
    margin-right: 10px;
    border-radius: 44px;
}

.assign-mem-popup .partner-item .partner-img .text-profile {
    height: 44px;
    width: 44px;
    text-align: center;
    background-color: #EAEDF0;
    margin-right: 10px;
    text-transform: uppercase;
    border-radius: 44px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0px;
}

.assign-mem-popup .partner-dtls h5 {
    font-size: 16px;
    font-weight: 700;
}

.assign-mem-popup .partner-dtls span {
    font-size: 13px;
}

.assign-mem-popup .lm-angle-up-icon {
    margin-bottom: -3px;
}

.assign-mem-popup .lm-angle-down-icon {
    margin-top: -3px;
}

.assign-mem-popup .lm-tag-box {
    border: 1px solid #EAEDF0;
    padding: 6px 12px;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.assign-mem-popup .lm-name-tag {
    background: #8080AE;
    border-radius: 4px;
    padding: 4px 10px;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}

.assign-mem-popup .lm-name-tag .remove-tag {
    margin-left: 10px;
}



@media (max-width:575px) {
    
    .loan-app-screen .filter-search-group .select__control{
        margin-right: 10px;
        width: 170px;
    }    
    
    .loan-app-screen .filter-search-group .lm-select,
    .loan-app-screen .filter-search-group .lm-search-bar {
        width: 100%;
    }

    .loan-app-screen .filter-search-group .lm-search-bar input {
        width: 100%;
    }

}

.plus-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
    border-radius: 25px;
    background-color: #F0F0F0; /* Light gray background */
    color: #007BFF; /* Blue color for text */
    font-size: 12px; /* Font size for the text */
    font-weight: bold; /* Bold text */
    border: 1px solid #007BFF; /* Blue border */
    cursor: pointer;
    text-align: center;
    margin-left: 0px; /* Spacing between the text and button */
    transition: background-color 0.3s, border-color 0.3s;
  }