.register-body {
    background: #F0F2FF;
    padding: 103px 20px;
}

.register-body main {
    width: 870px;
    margin: 0 auto 20px;
}

.register-body h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 48px;
}

.register-body h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 8px 0 40px;
}

.register-body h4 span {
    color: #8A8B98;
}

.register-box {
    background-color: #FFFFFF;
    padding: 40px 48px;
    box-shadow: 0px 16px 32px 0px #17226A14;
    position: relative;
}

.register-body .step-1::before {
    content: ' ';
    position: absolute;
    width: 50%;
    height: 8px;
    top: 0;
    left: 0;
    background-color: #4057E3;
}

.register-body .step-2::before {
    content: ' ';
    position: absolute;
    width: 50%;
    height: 8px;
    top: 0;
    right: 0;
    background-color: #4057E3;
}

.register-body .register-box .form-group {
    margin-bottom: 36px;
}

.register-body .btn-grp {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.register-body .form-btn {
    padding: 10px 24px;
    border-radius: 4px;
    background: #4057E3;
    transition: all .3s;
    color: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
    display: block;
    max-width: max-content;
    border: 1px solid;
}

.register-body .form-btn:hover {
    background: #586EF2;
    color: #fff;
}

.register-body .btn-back {
    transition: 0.3s;
    width: max-content;
    color: #07142D;
    font-weight: 700;
    font-size: 16px;
    padding: 6px 16px;
    background-color: #fff;
    border: 1px solid #07142D;
    border-radius: 4px;
}

.register-body .btn-back:hover {
    transition: 0.3s;
    color: #fff;
    background: #07142D;
}

.register-body .btn-back:hover a{
    color: #fff;
}

.register-body .cust-check {
    display: flex;
}

.register-body .cust-check input[type=checkbox] {
    position: relative;
    border: 2px solid #07142D;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 7px .6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: .5;
}

.register-body .cust-check input[type=checkbox]:hover {
    opacity: 1;
}

.register-body .cust-check input[type=checkbox]:checked {
    background-color: #4057E3;
    opacity: 1;
    border: 2px solid #4057E3;
}

.register-body .cust-check input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}

.register-body .cust-check a {
    color: #4057E3;
    text-decoration: underline;
}

.register-body .cust-check label {
    cursor: pointer;
}

.register-body .input-dollar {
    position: relative;
}

.register-body .input-dollar::after {
    content: '$';
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    right: 0;
    top: 42px;
    height: max-content;
    border-bottom: 1px solid #07142d;
    padding: 0 0 7px 5px;
}

.register-body .captcha-box {
    margin-top: -15px;
}

.register-validation-box{
    text-align: center;
    width: 840px;
    padding: 12px;
    margin: 0 auto 0;
    background: #FFDCDD;
    color: #FD151B;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 4px 32px 0px #17226A14;
}

@media (max-width:575px) {
    .register-body {
        padding: 45px 10px;
    }

    .register-body main {
        width: 100%;
    }

    .register-box {
        padding: 40px 20px;
    }

    .register-body h2 {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 30px;
    }

   .register-body  .btn-grp {
        margin-top: 20px;
    }

    .register-validation-box {
        width: 100%;
    }
}