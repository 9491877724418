.profile-header {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px;
    border-bottom: 1px solid #EAEDF0;
    background-color: #FFFFFF;
    place-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: calc(100% - 265px);
    margin-left: 265px;
}

.profile-header .section-name h6 {
    font-size: 16px;
    font-weight: 700;
}

.user-dtl {
    display: flex;
    position: relative;
    cursor: pointer;
}

.user-dtl .user-pro-pic img {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 10px;
}

.user-dtl .user-name {
    font-size: 14px;
    font-weight: 500;
}

.user-dtl .user-designation {
    font-size: 12px;
    font-weight: 500;
    color: #99A4B1;
}

.signout-modal h3{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.signout-modal .modal-footer {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
}

.signout-modal .modal-footer button{
    margin: 0;
    width: 100%;
}

.profile-header .profile-text-img h2{
    font-size: 16px;
    font-weight: 500;
    color: #07142D;
    height: 36px;
    width: 36px;
    text-align: center;
    border-radius: 100%;
    background-color: #F3F4F6;
    padding: 9px 0;
}

.user-pro-pic{
    display: flex;
}

.user-dtl .user-pro-pic .logout-dropdown img {
    margin-right: 6px;
    height: 20px;
    width: 20px;
    border-radius: 0;
}

@media (min-width:575px){
    .signout-modal .modal-dialog{
        width: 420px;
    }
}

@media (max-width:1024px) {
    .profile-header {
        width: 100%;
        margin-left: 0;
        padding: 14px 12px;
    }
}

@media (max-width:575px) {
    .dtl-sec {
        position: absolute;
        padding: 10px 20px 5px;
        box-shadow: 2px 2px 32px 0px #2E373724;
        border-radius: 8px;
        background: #fff;
        top: 45px;
        right: 0;
        width: max-content;
        display: none;
        transition: 0.3s;
    }

    .dtl-sec::before {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #FFFFFF;
        position: absolute;
        top: -8px;
        right: 6px;
    }

    .logout-dropdown a img {
        margin-right: 5px;
    }

    .user-pro-pic:hover .dtl-sec {
        display: block;
        transition: 0.3s;
    }

    .logout-dropdown a{
        padding: 12px 0;
    }

    .logout-dropdown {
        visibility: visible;
        opacity: 1;
        box-shadow: unset;
        position: relative;
        margin-top: 5px;
    }

    .user-dtl .user-pro-pic .logout-dropdown img {
        margin-right: 6px;
        height: 20px;
        width: 20px;
        border-radius: 0;
    }
}