.main-side-panel {
    background-color: #22315D;
    padding: 24px 12px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100vh;
    width: 265px;
}

.main-logo img {
    margin: 0 16px 30px;
}

.panel-navigation {
    height: calc(100vh - 190px);
    overflow: auto;
    margin-bottom: 20px;
}

.panel-nav-item {
    padding: 12px 16px;
    margin-bottom: 6px;
    border-radius: 6px;
    display: flex;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    position: relative;
}

.panel-nav-item:hover , .panel-navigation .active {
    color: #FFFFFF;
    background-color: #384A7D;
    transition: 0.3s;
}

.panel-nav-item a {
    color: #FFFFFF;
}

.panel-dropdown::after {
    content: ' ';
    height: 18px;
    width: 18px;
    background-image: url('../../../src/assets/icons/angle-down.svg');
    position: absolute;
    right: 10px;
    top: 15px;
    transition: 0.3s;
}

.panel-dropdown[aria-expanded="true"]::after {
    transform: rotate(180deg);
    transition: 0.3s;
}

.panel-dropdown {
    background-color: #384A7D;
    margin-bottom: 0px;
    border-radius: 6px 6px 0 0;
}

.panel-nav-item[aria-expanded="true"] i {
    background-image: url('../../../src/assets/icons/users-hov.svg');
}

.panel-dropdown-box {
    padding: 0px 16px 12px;
    background-color: #384A7D;
    border-radius: 0 0 6px 6px;
    margin-bottom: 6px;
}

.panel-dropdown-item-group {
    display: grid;
    gap: 10px;
    padding-left: 20px;
    margin-left: 10px;
    border-left: 1px solid #576AA0
}

.panel-dropdown-box a {
    font-size: 14px;
    width: 100%;
    color: #89A0BA;
}

.panel-dropdown-box .active {
    color: #fff;
}

.panel-dropdown-box a:hover {
    color: #FFFFFF;
}

.panel-nav-item[aria-expanded="false"] {
    background-color: #22315D;
}

.main-side-panel hr {
    border: 0;
    margin: 15px 18px 10px;
    border-top: 1px solid #384A7D;
}

.copyright-sec {
    padding: 0 16px;
}

.copyright-sec p {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.copyright-sec a {
    font-size: 14px;
    font-weight: 500;
    color: #999EBF;
    text-decoration: underline;
}

.panel-navigation::-webkit-scrollbar {
    width: 0;
}

.hamburger .line1,
.hamburger .line3 {
    height: 2px;
    width: 15px;
    background-color: #22315D;
    border-radius: 10px;
    margin-bottom: 5px;
}

.hamburger .line2 {
    height: 2px;
    width: 10px;
    background-color: #22315D;
    border-radius: 10px;
    margin-bottom: 5px;
}

.logout-dropdown {
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    position: absolute;
    right: 0;
    width: max-content;
    display: inline-grid;
    transition: 0;
    visibility: hidden;
    opacity: 0;
}

.dtl-sec:hover .logout-dropdown {
    transition: 0.3s;
    visibility: visible;
    opacity: 1;
}

.logout-dropdown a {
    padding: 12px 20px 12px 20px;
    color: #07142D;
    transition: 0.3s;
    border-bottom: 1px solid #EAEDF0;
    font-size: 13px;
}

.logout-dropdown a:last-child {
    border-bottom: unset;
}

.logout-dropdown a:hover {
    background-color: #F8F8F8;
}

.logout-dropdown a img {
    margin-top: -3px;
    margin-right: 10px;
}

/* Side panel icons */

.panel-nav-item i{
    height: 24px;
    width: 24px;
    margin-right: 10px;
}

/* Dashboard Icon */
.lm-dashboard-icon {
    background-image: url('../../assets/icons/dashboard.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-dashboard-icon,
.panel-nav-item.active .lm-dashboard-icon {
    background-image: url('../../assets/icons/dashboard-hov.svg');
    transition: 0.3s;
}

/* Inquiries Icon */
.lm-inquiries-icon {
    background-image: url('../../assets/icons/inquiries.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-inquiries-icon,
.panel-nav-item.active .lm-inquiries-icon {
    background-image: url('../../assets/icons/inquiries-hov.svg');
    transition: 0.3s;
}

/* Inquiries Icon */
.lm-lending-criteria-icon {
    background-image: url('../../assets/icons/lending-criteria.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-lending-criteria-icon,
.panel-nav-item.active .lm-lending-criteria-icon {
    background-image: url('../../assets/icons/lending-criteria-hover.svg');
    transition: 0.3s;
}

/* Loan App Icon */
.lm-loan-app-icon {
    background-image: url('../../assets/icons/loan-app.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-loan-app-icon,
.panel-nav-item.active .lm-loan-app-icon {
    background-image: url('../../assets/icons/loan-app-hov.svg');
    transition: 0.3s;
}

/* Logs Icon */
.lm-logs-icon {
    background-image: url('../../assets/icons/logs.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-logs-icon,
.panel-nav-item.active .lm-logs-icon {
    background-image: url('../../assets/icons/logs-hov.svg');
    transition: 0.3s;
}

/* Settings Icon */
.lm-settings-icon {
    background-image: url('../../assets/icons/settings.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-settings-icon,
.panel-nav-item.active .lm-settings-icon {
    background-image: url('../../assets/icons/settings-hov.svg');
    transition: 0.3s;
}

/* Templates Icon */
.lm-templates-icon {
    background-image: url('../../assets/icons/templates.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-templates-icon,
.panel-nav-item.active .lm-templates-icon {
    background-image: url('../../assets/icons/templates-hov.svg');
    transition: 0.3s;
}

/* Users Icon */
.lm-users-icon {
    background-image: url('../../assets/icons/users.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.panel-nav-item:hover .lm-users-icon,
.panel-nav-item.active .lm-users-icon {
    background-image: url('../../assets/icons/users-hov.svg');
    transition: 0.3s;
}


@media (min-width:1025px) {
    .hamburger {
        display: none;
    }

    .close-panel {
        display: none;
    }

    .menu-overlay {
        display: none;
    }
}

@media (max-width:1024px) {
    .main-side-panel {
        z-index: 101;
        transform: translateX(-100%);
        transition: transform 0.5s ease-in-out;
    }

    .side-panel-open {
        display: block;
        transform: translateX(0);
    }

    .close-panel img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
    }

    .menu-overlay-open {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: #17336126;
        z-index: 100;
    }
}

@media (max-width:575px) {
    .main-side-panel {
        width: 85%;
    }
}