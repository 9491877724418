@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");

.website-wrap {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;
  margin: 0;
  background-color: #ffffff;
  padding-top: 74px;
}

.website-wrap p {
  font-family: "Manrope", sans-serif;
  color: #22315d;
}

.website-wrap p:last-child {
  margin-bottom: 0;
}

.website-wrap a,
.website-wrap a:hover {
  text-decoration: none;
  color: #22315d;
}

.website-wrap h1,
.website-wrap h2,
.website-wrap h3,
.website-wrap h4,
.website-wrap h5,
.website-wrap h6 {
  font-family: "Sora", sans-serif;
  color: #07142d;
}

.website-wrap label {
  font-family: "Manrope", sans-serif;
  color: #07142d;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}

.website-wrap ::placeholder {
  font-size: 18px;
  color: #8A8B98;
}

.website-wrap input {
  font-size: 18px;
}


.website-wrap input,
.website-wrap .form-control {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #07142d;
  color: #07142d;
  font-weight: 500;
  border-radius: 0;
  padding: 0 0 10px;
  width: 100%;
  transition: 0.3s;
}


.website-wrap input:hover,
.website-wrap input:focus-visible,
.website-wrap .form-control:focus-visible,
.website-wrap .form-control:focus {
  outline: none;
  box-shadow: unset;
  border-color: #07142d;
}

.website-wrap .error-field input {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #FD151B;
}


.website-wrap select.form-control:not([size]):not([multiple]) {
  height: unset;
}

.website-wrap .basic-single .select__control {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #07142d;
  color: #07142d;
  border-radius: 0;
}

.website-wrap .basic-single .select__value-container {
  padding: 0;
}

.website-wrap .basic-single .select__single-value {
  color: #07142d;
}

.website-wrap .basic-single .select__control--is-focused {
  box-shadow: none;
}

.website-wrap .select__indicator-separator {
  display: none;
}

.website-wrap .select__placeholder {
  font-size: 18px;
  color: #8A8B98;
  font-weight: 500;
  padding-bottom: 8px;
}

.website-wrap .btn.focus,
.website-wrap .btn:focus {
  outline: 0;
  box-shadow: unset;
}

.website-wrap .asterisk {
  color: #fd151b;
}

.website-wrap .heading-1 {
  font-family: "Sora", sans-serif;
  color: #22315d;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

.website-wrap .heading-2 {
  font-family: "Sora", sans-serif;
  color: #22315d;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}

.website-wrap .container {
  max-width: 1272px;
  padding: 0 15px;
}

.website-wrap .link-secondary {
  position: relative;
  place-self: center;
  height: max-content;
  font-size: 16px;
    cursor: pointer;
    font-weight: 500;
}

.website-wrap .link-secondary::after {
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #07142d;
  position: absolute;
  bottom: -4px;
  left: 0;
  transition: 0.3s;
}

.link-secondary-no-border::after{
  display: none;
}

.website-wrap .error-message {
  font-size: 14px;
}

/* NOt found page css */
.not-found-page-body {
  padding: 100px 20px;
  background-color: #F4F3FF;
}

.not-found-page-body .back-btn {
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #07142D;
  padding: 16px 30px;
  border-radius: 4px;
  transition: 0.3s;
}

.not-found-page-body .back-btn:hover {
  background-color: #07142D;
  color: #FFFFFF;
  transition: 0.3s;
}

@media (max-width: 575px) {
  .website-wrap .login-body,
  .website-wrap .forgot-body {
    min-height: 100vh;
  }

  .heading-1 {
    font-size: 36px;
  }

  .heading-2 {
    font-size: 24px;
  }

  .not-found-page-body {
    padding: 70px 10px;
  }
}