.login-body{
    background: #F0F2FF;
    padding: 102px 20px;
}
.website-wrap .login-body .heading-1{
    color: #07142D;
    font-family: "Sora", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: center;
}
.website-wrap .login-body p{
    text-align: center;
    color: #07142D;
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.18px;
}

.link-secondary:hover{
    color: #4057E3 !important;
}

.login-box{
    width: 450px;
    margin: 0 auto 0;
    background-color: #FFFFFF;
    padding: 45px;
    box-shadow: 0px 16px 32px 0px #17226A14;
}

.login-validation-box{
    text-align: center;
    width: 450px;
    padding: 12px;
    margin: 0 auto 0;
    background: #FFDCDD;
    color: #FD151B;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 4px 32px 0px #17226A14;
}


.captcha-box{
    margin-top: 25px;
    margin-bottom: 20px;
}
.login-box .form-group{
    margin-bottom: 30px;
}
.btn-login{
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    background-color: #4057E3;
    border-radius: 4px;
}
.btn-login:hover {
    background: #586EF2;
    color: #fff;
}

.input-password{
    position: relative;
}

.website-wrap .input-password .pass_eye{
    position: absolute;
    right: 0;
    top: 45px;
    cursor: pointer;
    opacity: 0.4;
}

.website-wrap .input-password .active_pass{
    opacity: 1 !important;
}

.mas-popup .modal-content{
    padding: 20px 40px 0;
}

.mas-popup h3{
    font-family: "Sora", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
}

.mas-popup p{
    color: #8A8B98;
    font-size: 18px;
    font-weight: 500;
}

.mas-popup .d-flex{
    gap: 20px;
}

.mas-popup .back-btn {
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #07142D;
    padding: 9px 24px;
    border-radius: 4px;
    transition: 0.3s;
    width: 100%;
}

.btn-confirm{
    padding: 9px 24px;
    width: 100%;
}

.mas-popup .back-btn:hover {
    background-color: #07142D;
    color: #FFFFFF;
    transition: 0.3s;
}

.mas-popup .mas-icon{
    width: 56px;
}

.mas-popup .modal-footer {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 0 30px;
    gap: 15px;
}

.mas-popup .modal-footer button{
    margin: 0;
}

@media (min-width:575px){
    .mas-popup .modal-dialog{
        width: 432px;
    }
}

@media (max-width:575px) {
    .login-body{
    padding: 40px 10px;
}
    .login-box{
    width: 100%;
    padding: 40px 20px;
    }
    .captcha-box img{
        width: 100%;
    }

    .login-validation-box {
        width: 100%;
    }
}